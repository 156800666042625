<template>
  <div class="risk-matrix">
    <iframe :src="cdbUrl" title="Risk Matrix"></iframe>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "RiskMatrixView",
  computed: {
    ...mapGetters({
      cdbUrl: 'authStore/cdbRiskMatrixUrl'
    }),
  },
}
</script>

<style scoped>

  iframe {
    width: 100%;
    height: 100vh;
    border: none;
  }

 .risk-matrix {
    overflow: hidden;
    max-height: 100vh;
  }

</style>